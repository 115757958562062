import { createContext, useContext, useEffect, useRef, useState } from 'react';
import Editor from 'react-simple-wysiwyg';
import Fa from '../components/FontAwesome';
import { Link, useParams, useNavigate } from 'react-router-dom';
import { useQuery } from '@tanstack/react-query';
import sql from '../common/sql';
import { ComandaEntity } from '../../../backend/src/shared/entity';
import Loading from '../components/Loading';

const HasChangesContext = createContext<{ hasChanges: boolean, setHasChanges: React.Dispatch<React.SetStateAction<boolean>> }>(null as any);

const ComandaPage = () => {
  const { idTemplate = 1, idComanda = 0 } = useParams<{ idTemplate: string, idComanda: string }>();
  const [model] = useState('Model standard');
  const [isSaving, setIsSaving] = useState(false);
  const elem = useRef<HTMLDivElement>(null);
  const [articles, setArticles] = useState<HTMLElement[]>([]);
  const [preview, setPreview] = useState(false);
  const [hasChanges, setHasChanges] = useState(false);
  const navigate = useNavigate();

  const {
    data,
    isLoading,
    error,
  } = useQuery<Array<ComandaEntity>>({
    queryKey: ["getComandaQuery", idTemplate, idComanda],
    queryFn: ({ queryKey: [, idTemplate = 0, idComanda] }) =>
      sql.getComandaQuery({ idTemplate, idComanda } as any),
    refetchOnWindowFocus: false,
    refetchOnReconnect: false,
    cacheTime: 0, // 0 means no cache, will be removed from cache when component is unmounted
  });
  if (data && data.length && +idComanda === 0) {
    navigate(`/comanda/${data[0].Id}`);
  }
  useEffect(() => {
    if (data && data.length && elem.current && !articles.length) {
      console.log(data[0].Id, 'useEffect');
      const html = data[0].HtmlContent;
      const body = html.substring(html.indexOf('<body>') + 6, html.indexOf('</body>'));
      elem.current.innerHTML = body;
      setTimeout(() => {
        if (elem.current) {
          const articles = elem.current.querySelectorAll('article');
          setArticles(Array.from(articles));
        }
      });
    }
  }, [data, articles]);

  const handleSave = () => {
    setIsSaving(true);
    const form = data![0];
    const body = elem.current!.innerHTML;
    form.HtmlContent = form.HtmlContent.split('<body>')[0] + '<body>' + body + '\n</body>' + form.HtmlContent.split('</body>')[1];
    sql
      .saveEntity("dbo.Comanda", { ...form, PdfContent: undefined })
      .then(() => setHasChanges(false))
      .catch(console.error)
      .finally(() => setIsSaving(false));
  }
  const reportUrl = (
    params: { [key: string]: number | string | Date }
  ) =>
    "/api/report/" +
    btoa(
      JSON.stringify(params)
    );

  if (error && (error as any).message) {
    return <div>{(error as any).message}</div>;
  }

  return (
    <HasChangesContext.Provider value={{ hasChanges, setHasChanges }} >
      <nav className='mb-3'>
        <ul>
          <li className='text-muted'><i>{model}</i></li>
        </ul>
        <ul>
          <li><Link to="/" onClick={e => [e.preventDefault(), setPreview(!preview)]}>
            <Fa light={`eye${preview ? '-slash' : ''} fa-2xl`} />
          </Link>
          </li>
          {hasChanges ? <li>
            <Link to="/" onClick={e => [e.preventDefault(), handleSave()]}>
              <Fa light='save fa-2xl' />
            </Link>
          </li> : <li>
            <Link to={reportUrl({
              idComanda,
              stamp: new Date(),
            })}
              target="_blang">
              {/* <img src="/images/pdf.svg" alt="pdf" className='img img-icon' /> */}
              <Fa light='file-pdf fa-2xl' />
            </Link>
          </li>}
        </ul>
      </nav>
      {
        !preview && articles.map((article, index) => <ArticleEditor article={article} key={index} />)
      }
      <div ref={elem} className={preview ? '' : 'hidden'}></div>
      {(isLoading || isSaving) && <Loading />}
    </HasChangesContext.Provider>
  );
}

export default ComandaPage;

const ArticleEditor = ({ article = {} as HTMLElement }) => {
  const [label] = useState(article.querySelector('label')?.innerHTML || "");
  const [text, setText] = useState((article.querySelector('main')?.innerHTML || "").trim());
  const control = article.getAttribute('aria-controls') || 'input';
  const { setHasChanges } = useContext(HasChangesContext);
  const handleChange = (e: { target: { value: string } }) => {
    setText(e.target.value);
    article.querySelector('main')!.innerHTML = e.target.value;
    setHasChanges(true);
  }
  return (
    <section className='form-section mb-2'>
      <label dangerouslySetInnerHTML={{ __html: label }}></label>
      {control === 'input' && <input value={text} onChange={handleChange} />}
      {control === 'textarea' && <textarea value={text} rows={3} onChange={handleChange} />}
      {control === 'texteditor' && <Editor value={text} onChange={handleChange} style={{ height: '100px' }} />}
    </section>
  )
}
