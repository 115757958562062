import { ChangeEvent, SyntheticEvent, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import Input from "../../components/Input";
import { logoutUser, passwordReset } from "../../common/user";
import { useUser } from "../../hooks/useUser";
import LoginContainer from "../../components/LoginContainer";
import ErrorBox from "../../components/ErrorBox";
import Title from "../../components/Title";
import { SqlError } from "../../common/sql";

const PasswordResetPage = () => {
  const { token } = useParams();
  const navigate = useNavigate();
  const { user, setUser } = useUser();
  const [model, setModel] = useState({
    password: "",
    passwordRepeat: "",
  });

  const [error, setError] = useState<SqlError>();
  if (user.email) logoutUser().then(setUser);

  const handleChange = (e: ChangeEvent<HTMLInputElement>) => {
    setModel((model) => ({ ...model, [e.target.name]: e.target.value }));
    setError(undefined);
  };

  const handleSubmit = (e: SyntheticEvent) => {
    e.preventDefault();
    if (model.password !== model.passwordRepeat)
      return setError({
        number: "",
        message:
          "<b>Crează o parolă</b> și <b>repetă parola</b> trebuie să fie identice",
      });
    passwordReset(token as string, model.password)
      .then(() => {
        setUser();
        navigate("/");
      })
      .catch(setError);
  };

  return (
    <LoginContainer>
      <form>
        <Title>Resetare parolă</Title>
        <Input
          type="password"
          name="password"
          placeholder="Type your password"
          // pattern="^(?=.*[A-Za-z])(?=.*\d)[A-Za-z\d]{8,}$"
          required
          value={model.password}
          onChange={handleChange}
        >
          Crează o parolă
        </Input>

        <Input
          type="password"
          name="passwordRepeat"
          placeholder="Type your password"
          // pattern="^(?=.*[A-Za-z])(?=.*\d)[A-Za-z\d]{8,}$"
          required
          value={model.passwordRepeat}
          onChange={handleChange}
        >
          Repetă parola
        </Input>

        <ErrorBox>{error}</ErrorBox>
        <button type="submit" onClick={handleSubmit}>
          Setează noua parolă
        </button>
      </form>
    </LoginContainer>
  );
};

export default PasswordResetPage;
