import { Link } from "react-router-dom";
import ConfigUser from "../../components/ConfigUser";
import { useUser } from "../../hooks/useUser";

const ConfigPage = () => {
  const { user } = useUser();

  if (user.role === "admin") return <ConfigUser />;

  return (
    <>
      <p>
        Trebuie să fii autentificat pentru a accesa{" "}
        <strong>Setări utilizator</strong> !
      </p>
      <Link to="/login">Autentificare</Link>
    </>
  );
};

export default ConfigPage;
