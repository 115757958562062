import React from 'react'
import Fa from './FontAwesome';

const Pagination = ({
    pageNum = 1,
    pageSize = 100,
    totalRows = 0,
    setPageNum = (pageNum: number) => { },
}) => {
    const pages = Math.ceil(totalRows / pageSize);
    return (
        <div className="pagination d-flex justify-content-evenly align-items-center">
            <a href='/' className='d-flex align-items-center' aria-disabled={pageNum === 1}
                onClick={(e) => [e.preventDefault() ,setPageNum(1)]}
            >
                <Fa duotone='angles-left' /> Prima pagină
            </a>
            <a href='/' className='d-flex align-items-center' aria-disabled={pageNum === 1}
                onClick={(e) => [e.preventDefault() ,setPageNum(pageNum - 1)]}
            >
                <Fa solid='angle-left' /> Pagina anterioară
            </a>
            <span className="text-center text-bold">
                {pageNum} / {pages}
            </span>
            <a href='/' className='d-flex align-items-center' aria-disabled={pageNum === pages}
                onClick={(e) => [e.preventDefault() ,setPageNum(pageNum + 1)]}
            >
                Pagina următoare <Fa solid='angle-right' />
            </a>
            <a href='/' className='d-flex align-items-center' aria-disabled={pageNum === pages}
                onClick={(e) => [e.preventDefault() ,setPageNum(pages)]}
            >
                Ultima pagină <Fa duotone='angles-right' />
            </a>
        </div>
    )
}

export default Pagination