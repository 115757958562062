
const Title = ({ children, icon = "", iconStyle = "fa-regular" }: any) => {

  return (
    <h4>
      {icon && <i className={`fa-lg ${iconStyle} ${icon} mx-2`}></i>}
      <span> {children} </span>
      <hr />
    </h4>
  );
};

export default Title;
