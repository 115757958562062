import { createContext, useContext, useState } from "react";
import { UserInfo, cookieUserInfo } from "../common/user";

const UserContext = createContext<{
  user: UserInfo;
  setUser: (user: UserInfo) => void;
}>(null as any);

export function UserProvider({ children }: { children: React.ReactNode }) {
  const [user, setUser] = useState(cookieUserInfo());
  return (
    <UserContext.Provider value={{ user, setUser }}>
      {children}
    </UserContext.Provider>
  );
}

export const useUser = () => {
  const { user, setUser } = useContext(UserContext);
  return { user, setUser: () => setUser(cookieUserInfo()) };
};
