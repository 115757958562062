import { useEffect, useState } from "react";
import { loginUser, logoutUser } from "../../common/user";
import { useUser } from "../../hooks/useUser";
import { Link, useParams } from "react-router-dom";
import LoginContainer from "../../components/LoginContainer";
import ErrorBox from "../../components/ErrorBox";
import Title from "../../components/Title";
import { SqlError } from "../../common/sql";

const ValidateEmailPage = () => {
  const { token } = useParams();
  const { user, setUser } = useUser();
  const [error, setError] = useState<SqlError>();

  useEffect(() => {
    logoutUser().then(() => {
      setUser();
      loginUser({ email: token || "" })
        .then(() => setUser())
        .catch(setError);
    });
  }, [token, setUser, setError]);

  return (
    <LoginContainer>
      <Title>Validare adresă de email</Title>
      <ErrorBox>{error}</ErrorBox>
      <form>
        {user.email && (
          <>
            <aside>
              <ul>
                <li>
                  <small>
                    Mulțumim pentru confirmarea contului <b>{user.email}</b> !
                  </small>
                </li>
                <li>
                  <Link to="/dosar-index">
                    <i className="fa-fw fa-regular fa-folder-tree"></i> Dosare
                    transport
                  </Link>
                </li>
                <li>
                  <Link to="/config">
                    <i className="fa-fw fa-regular fa-screwdriver-wrench"></i>{" "}
                    Sincronizare baza de date
                  </Link>
                </li>
              </ul>
            </aside>
          </>
        )}
      </form>
    </LoginContainer>
  );
};

export default ValidateEmailPage;
