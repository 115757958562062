import { Link } from "react-router-dom";
import { useUser } from "../hooks/useUser";
import Fa from "./FontAwesome";
import { useContext } from "react";
import { NotImplementedContext } from "../App";

const Navbar = () => {
  const { user } = useUser();
  return (
    <header>
      <nav>
        <ul>
          <li>
            <Link to="/">
              <img src="/logo.png" className="logo" alt="logo" />
            </Link>
          </li>
        </ul>
        {user.email && <ul>
          <li>
            <Link to="/"><Fa regular="arrow-down-z-a" />Listă Comenzi</Link>
          </li>
          <li>
            <UserInfo />
          </li>
        </ul>}
      </nav>
      <hr />
    </header>
  );
};

export default Navbar;

const UserInfo = () => {
  const { setOpen } = useContext(NotImplementedContext);
  const { user } = useUser();
  if (!user?.email) return <Link to="/login">
    <Fa regular="sign-in-alt" />Login
  </Link>;
  return (
    <Link to="/config">
      <Fa regular="user" /> {user.name}
    </Link>
  );
}
