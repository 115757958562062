import { useContext, useState } from 'react';
import Fa from '../components/FontAwesome';
import { Link } from 'react-router-dom';
import { useQuery } from '@tanstack/react-query';
import sql from '../common/sql';
import { ComandaEntity } from '../../../backend/src/shared/entity';
import Loading from '../components/Loading';
import { NotImplementedContext } from '../App';
import { formatDate } from '../common/utils';
import { useDebounce } from 'use-debounce';
import Pagination from '../components/Pagination';

const HomePage = () => {
  const { setOpen } = useContext(NotImplementedContext);
  const [pageNum, setPageNum] = useState(1);
  const [pageSize, setPageSize] = useState(100);
  const [searchText, setSearchText] = useState('');
  const {
    data,
    isLoading,
    error,
  } = useQuery<Array<ComandaEntity & { RowsCount: number }>>({
    queryKey: ["getComandaListQuery", useDebounce(searchText, 500), pageNum, pageSize],
    queryFn: () =>
      sql.getComandaListQuery({ searchText, pageNum, pageSize })
  });

  if (error && (error as any).message) {
    return <div>{(error as any).message}</div>;
  }

  return (
    <>
      <table className='table table-responsive'>
        <thead>
          <tr>
            <td colSpan={7}>
              <input
                type="search"
                name="fullText"
                className="m-0"
                placeholder='Caută comandă după nume, telefon, email, adresă, etc.'
                value={searchText}
                onChange={e => setSearchText(e.target.value)}
              ></input>
            </td>
            <td colSpan={2} className='text-end'>
              <Link to="/comanda/0/1">
                <Fa duotone='file-circle-plus fa-xl' /> Comandă nouă
              </Link>
            </td>
          </tr>
          <tr>
            <td colSpan={9}>
              <Pagination
                pageNum={pageNum}
                pageSize={pageSize}
                totalRows={data?.[0]?.RowsCount || 0}
                setPageNum={setPageNum} />
            </td>
          </tr>
        </thead>
        <tbody>
          {data && data.length > 0 && <tr>
            <td>#</td>
            <ComandaRowDetail html={data[0].HtmlContent} elem='label' />
          </tr>}
          {data && data.map((item, index) => (
            <tr key={index}>
              <td>{index+1}</td>
              <ComandaRowDetail html={item.HtmlContent} user={item.User + ', ' + formatDate(item.Stamp, "time")} id={item.Id} />
              {/* <td> <Link to="" onClick={e => [e.preventDefault(), setOpen(true)]}>
                <Fa thin='trash fa-xl text-danger' />
              </Link>
              </td> */}
            </tr>
          ))}
        </tbody>
      </table>
      {isLoading && <Loading />}
    </>
  );
}

export default HomePage;

const ComandaRowDetail = ({ id = 0, html = "", user = "", elem = "main" as "main" | "label" }) => {
  const items = html
    .split(`<${elem}>`)
    .filter((_, i) => i && i < 9)
    .map(el => el.split(`</${elem}>`)[0]);
  return (
    <>
      {
        items.map((item, index) => (
          <td key={index}>
            {index === 0 && elem === "main" && <><Link to={`/comanda/${id}`}>{item}</Link><br /><i><small>{user}</small></i></>}
            {(index > 0 || elem === "label") && <div dangerouslySetInnerHTML={{ __html: item }} />}
          </td>
        ))
      }
    </>
  );
}