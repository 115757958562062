import { BrowserRouter as Router, Routes, Route } from "react-router-dom";
import HomePage from "./pages/HomePage";
import LoginPage from "./pages/user/LoginPage";
import RegisterPage from "./pages/user/RegisterPage";
import Navbar from "./components/Navbar";
import { createContext, useState } from "react";
import PasswordRecoverPage from "./pages/user/PasswordRecoverPage";
import PasswordResetPage from "./pages/user/PasswordResetPage";
import ConfigPage from "./pages/user/ConfigPage";
import ValidateEmailPage from "./pages/user/ValidateEmailPage";
import Modal from "./components/Modal";
import { Toaster } from "sonner";
import { QueryClient, QueryClientProvider } from "@tanstack/react-query";
import { UserRole } from "./common/user";
import { useUser } from "./hooks/useUser";
import Forbidden from "./components/Forbidden";
import ComandaPage from "./pages/ComandaPage";

const queryClient = new QueryClient();

export const TitleContext = createContext<{
  title: JSX.Element;
  setTitle: (title: JSX.Element) => void;
}>(null as any);

export const NotImplementedContext = createContext<{
  open: boolean;
  setOpen: (open: boolean) => void;
}>(null as any);

function Auth(component: JSX.Element, ...roles: UserRole[]) {
  roles = roles.length ? roles : ["admin", "operator"];
  const { user } = useUser();
  if (!user?.email) return <LoginPage />;
  if (!roles.includes(user.role)) return <Forbidden />;
  return component;
}

const App = () => {
  const [title, setTitle] = useState(<span>comenzi transport</span>);
  const [notImplementedOpen, setNotImplementedOpen] = useState(false);
  return (
    <div className="App">
      <QueryClientProvider client={queryClient}>
        <TitleContext.Provider value={{ title, setTitle }}>
          <NotImplementedContext.Provider
            value={{ open: notImplementedOpen, setOpen: setNotImplementedOpen }}
          >
            <Router>
              <Navbar />
              <Routes>
                <Route index element={Auth(<HomePage />)} />
                <Route path="/home" element={Auth(<HomePage />)} />
                <Route path="/comanda/:idComanda" element={Auth(<ComandaPage />)} />
                <Route path="/comanda/:idComanda/:idTemplate" element={Auth(<ComandaPage />)} />

                <Route path="/login" element={<LoginPage />} />
                <Route path="/register" element={<RegisterPage />} />
                <Route
                  path="/password-recover"
                  element={<PasswordRecoverPage />}
                />
                <Route
                  path="/password-reset/:token"
                  element={<PasswordResetPage />}
                />
                <Route
                  path="/validate-email/:token"
                  element={<ValidateEmailPage />}
                />
                <Route path="/config" element={<ConfigPage />} />
              </Routes>
            </Router>
            <Toaster
              closeButton
              richColors
              position="bottom-right"
              theme="system"
              expand
            />
            <Modal />
            <dialog open={notImplementedOpen}>
              <article>
                <header>
                  <a href="/" onClick={e => setNotImplementedOpen(false)} aria-label="Close" className="close"></a>
                  Notificare
                </header>
                <p>
                  Această funcționalitate urmează să fie dezvoltată.
                </p>
              </article>
            </dialog>
          </NotImplementedContext.Provider>
        </TitleContext.Provider>
      </QueryClientProvider>
    </div>
  );
};

export default App;
