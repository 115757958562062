import React from 'react'

const Loading = () => {
    return (
        <dialog open>
            <article>
                <p style={{ minWidth: "500px" }}>
                    <progress></progress>
                </p>
                <p className='text-center'>Așteaptă ...</p>
            </article>
        </dialog>
    )
}

export default Loading