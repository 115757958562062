export type ThemeOption = "auto" | "light" | "dark";

export class ColorTheme {
    get storedTheme(): ThemeOption {
        return ((localStorage.getItem("theme") || "auto") as ThemeOption);
    }
    set storedTheme(theme: string) {
        localStorage.setItem("theme", theme);
    }

    get currentTheme(): Exclude<ThemeOption, "auto"> {
        if (this.storedTheme === "auto")
            return window.matchMedia("(prefers-color-scheme: dark)").matches
                ? "dark"
                : "light";
        return this.storedTheme;
    }

    get getTheme(): ThemeOption {
        if (this.storedTheme) {
            return this.storedTheme;
        }
        return this.currentTheme;
    }

    constructor() {
        this.setTheme(this.getTheme);
        window
            .matchMedia("(prefers-color-scheme: dark)")
            .addEventListener("change", () => {
                const storedTheme = this.getTheme;
                if (storedTheme !== "light" && storedTheme !== "dark") {
                    this.setTheme(this.getTheme);
                }
            });
    }

    setTheme(theme: ThemeOption) {
        theme === "auto" ?
            document.documentElement.removeAttribute("data-theme") :
            document.documentElement.setAttribute("data-theme", theme);
        return (this.storedTheme = theme);
    }
}

const env = {
    API_URL: '/api',
    THEME: new ColorTheme(),
};

export default env;
